<!-- 教师组织架构树 -->
<template>
    <div style="display:inline-block; margin-right: 10px;">


        <el-input :class="['down', dialogShow ? 'up' : '', inputSize == 'medium' ? 'mediumSize' : '']" @focus="showBox"
            :size="inputSize" :style="widthVal ? widthVal : 'width:250px;'" placeholder="请选择组织架构"
            suffix-icon="el-icon-arrow-down" v-model="deptIds" clearable @clear="clearEmpty">
        </el-input>

        <el-dialog class="cus_dialog" title="组织架构" :visible.sync="dialogShow" width="500px"
            :close-on-click-modal="false" @close="closeDialog" :modal="!modal ? false : true">

            <div>
                <el-input :size="inputSize" v-model="filterText" placeholder="搜索名称"
                    style="width:240px;margin-left:10px;margin-top: 5px;">
                    <el-button slot="append" icon="el-icon-search" @click="searchClss"></el-button>
                </el-input>
                <el-tree ref="tree" class="tree-line" icon-class="el-icon-circle-plus-outline" :indent='0'
                    :data="TreeData" :props="treeProps" :default-expand-all="true" :expand-on-click-node="false"
                    @node-click="checkNode" :filter-node-method="filterNode">
                    <div class="custom-tree-node" slot-scope="{ node, data }">
                        <span v-if="node.data.biye == 1" style="color:orangered" title="已毕业">{{ node.label }}</span>
                        <span v-else>{{ node.label }}</span>
                    </div>
                </el-tree>
            </div>

        </el-dialog>


    </div>
</template>

<script>

export default {
    model: {
        prop: 'comValue',
        event: 'setValue'
    },
    props: ["comValue", "widthVal", 'modal', 'size', 'isAll'],
    data() {
        return {
            deptIds: "",
            dialogShow: false,
            TreeData: [],
            treeProps: {
                label: "name",
            },
            filterText: "",
            biye: 0,
            inputSize: 'small'
        }
    },
    mounted() {
        this.biye = this.isAll == 1 ? '' : this.biye
        this.inputSize = this.size ?? 'small'
        // this.$http.post("/api/get_teacher_info").then(res => {
        //     if (res.data.class_names && res.data.class_names.length == 1 && window.pmid != 216) {
        //         this.deptIds = res.data.class_names[0].class_name
        //     }
        // })
    },
    watch: {
        filterText(val) {
            this.$refs.tree.filter(val);
        }
    },
    methods: {
        setBiye(n) {
            console.log(n !== this.biye, n, this.biye)
            if (n !== this.biye) {
                this.biye = n
                this.getTreeData()
            }
        },
        searchClss() {
            this.$refs.tree.filter(this.filterText);
        },
        showBox() {
            console.log(9494,this.TreeData)
            this.dialogShow = true

            if (this.TreeData.length == 0) {
                this.getTreeData()
            }
        },
        closeDialog() {
            this.dialogShow = false
        },
        getTreeData() {
            this.$http.post("/api/get_by_classes").then(res => {
                this.TreeData = res.data
            })
        },
        checkNode(a, b, c) {
            console.log(11111111,b.data.name)
            let arr = [];
            let getParent = opt => {
                if (opt.data && !Array.isArray(opt.data)) {
                    arr.push(opt.data)
                }
                if (opt.parent) {
                    getParent(opt.parent)
                }
            }
            getParent(b)
            this.$emit('setValue', b.data.name)
            this.$emit('change', b.data.name)
            this.$emit('changeType', b.data.type)
            this.deptIds = arr.reverse().map(e => { return e.name }).join("/")
            this.dialogShow = false
            arr = null
        },
        clearEmpty() {
            this.$emit('setValue', 0)
            this.$emit('change', 0)
            this.deptIds = ""
            this.dialogShow = false
        },
        filterNode(value, data) {
            if (!value) return true;

            return data.name.indexOf(value) !== -1;
        }

    }
}
</script>
<style>
.down {
    color: inherit;
}

.down .el-input__suffix-inner i {
    display: inline-block;
    transition: all ease-out 0.2s;
}

.up .el-input__suffix-inner i {
    display: inline-block;
    transform: rotateZ(-180deg)
}

.down input {
    text-align: left;
    clip: true;
    direction: rtl;
    font-size: 12px;
}

.mediumSize input {
    font-size: 14px;

}
</style>
// 以下为scss，记得去掉scoped，或者使用/deep/
<style lang="less">
.tree-line {
    .el-tree-node {
        position: relative;
        padding-left: 16px; // 缩进量
    }

    .el-tree-node__children {
        padding-left: 16px; // 缩进量
    }

    // 竖线
    .el-tree-node::before {
        content: "";
        height: 100%;
        width: 1px;
        position: absolute;
        left: -3px;
        top: -26px;
        border-width: 1px;
        border-left: 1px dashed #52627C;
    }

    // 当前层最后一个节点的竖线高度固定
    .el-tree-node:last-child::before {
        height: 38px; // 可以自己调节到合适数值
    }

    // 横线
    .el-tree-node::after {
        content: "";
        width: 24px;
        height: 20px;
        position: absolute;
        left: -3px;
        top: 12px;
        border-width: 1px;
        border-top: 1px dashed #52627C;
    }

    // 去掉最顶层的虚线，放最下面样式才不会被上面的覆盖了
    &>.el-tree-node::after {
        border-top: none;
    }

    &>.el-tree-node::before {
        border-left: none;
    }

    // 展开关闭的icon
    .el-tree-node__expand-icon {
        font-size: 16px;

        // 叶子节点（无子节点）
        &.is-leaf {
            color: transparent;
            // display: none; // 也可以去掉
        }
    }
}
</style>

<style scoped>
/deep/ .el-dialog__header {
    /* padding: 0 20px*/
}
</style>
